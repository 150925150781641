import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
    overflow: 'hidden',
  },
  contentContainer: {
    minWidth: '400px',
    width: '100%',
    maxWidth: '100%',
    padding: '0 24px !important',
  },
});
