import State from 'src/app/types/state';
import { createSelector } from 'reselect';
import DeleteSingleContractTransactionModalData from '../command/DeleteSingleContractTransactionModalData';

const getModalsData = (state: State) => state.backoffice.modals.data as DeleteSingleContractTransactionModalData;

const getLoading = (state: State) => state.backoffice.modals.loading as boolean;

export default createSelector([getModalsData, getLoading], (data, loading) => ({
  id: data?.id,
  status: data?.status,
  loading,
}));
