import { createAction } from 'redux-actions';

export const reducerName = 'ledger.row';

export interface IUpdateRequest {
  documentId: number;
  requestId: number;
}

export const updateRequest = createAction<IUpdateRequest>(`${reducerName}.UPDATE_REQUEST`);
