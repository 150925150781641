import { COLOURS } from '@core-ui/styles';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import React, { FC } from 'react';

const styles: SxProps<Theme> = {
  background: COLOURS.HUE_COCONUT.HUE_12,
  transform: 'none',
};

const ApproveDocumentTableModalSkeleton: FC = () => {
  return (
    <>
      <Skeleton width={200} height={70} sx={styles} />

      <Skeleton width={200} height={70} sx={styles} />
    </>
  );
};

export default ApproveDocumentTableModalSkeleton;
