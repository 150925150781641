import { Nullable } from '@core-ui/types';
import { WalletOutputSchema } from 'src/generated';
import { IWalletCreateEditModalData } from 'src/pages/backoffice/Modals/CreateEditWalletModal/actions';

export default class CreateEditWalletModalData implements IWalletCreateEditModalData {
  walletData: Nullable<WalletOutputSchema>;
  shouldRedirect: boolean;

  constructor(walletData: Nullable<WalletOutputSchema>, shouldRedirect: boolean) {
    this.walletData = walletData;
    this.shouldRedirect = shouldRedirect;
  }
}
