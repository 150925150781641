import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

const MODAL_OFFSET = 60;

export default (): Record<string, SxProps<Theme>> => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    height: `calc(100vh - ${MODAL_OFFSET}px)`,
  },
  paper: {
    padding: 0,
    backgroundColor: COLOURS.CLEAR,
    boxShadow: 'none',
  },
});
