import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { IOption, Nullable } from '@core-ui/types';
import { getEndOfTodayInUnix, getStartOfCurrentYearInUnix } from 'src/app/utils/dates';
import { UploadingSourceEnum } from 'src/generated';
import { LEDGER_DOCUMENT_TYPE_ENUM } from 'src/pages/backoffice/Ledger/consts';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface ILedgerFiltersState {
  name: string;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  tags: string[];
  erpUploadPending: boolean;
  myDuty: boolean;
  documentType: Nullable<IOption<LEDGER_DOCUMENT_TYPE_ENUM, LEDGER_DOCUMENT_TYPE_ENUM>[]>;
  vendor: Nullable<IOption<string, string>[]>;
  location: Nullable<IOption<string, string>[]>;
  uploadedBy: Nullable<IOption<string, string>[]>;
  uploadedFrom: Nullable<IOption<UploadingSourceEnum, UploadingSourceEnum>[]>;
  totalFrom: string;
  totalTo: string;
  costCenters: Nullable<IOption<string, string>[]>;
  costArticles: Nullable<IOption<string, string>[]>;
  erpCategories: Nullable<IOption<string, string>[] | IOption<string, string>>;
  walletIds: Nullable<IOption<string, string>[]>;
  contractIds: Nullable<IOption<string, string>[]>;
  requestIds: Nullable<IOption<string, string>[]>;
}

const defaultState = Immutable<ILedgerFiltersState>({
  name: '',
  dateFrom: String(getStartOfCurrentYearInUnix()),
  dateTo: String(getEndOfTodayInUnix()),
  tags: [],
  erpUploadPending: false,
  myDuty: false,
  documentType: null,
  vendor: null,
  location: null,
  uploadedBy: null,
  uploadedFrom: null,
  totalFrom: '',
  totalTo: '',
  costCenters: null,
  costArticles: null,
  erpCategories: null,
  walletIds: null,
  contractIds: null,
  requestIds: null,
});

export default handleActions({}, reducer<typeof defaultState>(actions.reducerName, defaultState));
