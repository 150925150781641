import State from 'src/app/types/state';
import { getClientCostArticlesDictOptions } from 'src/dictionary/selector';
import { createSelector } from 'reselect';
import SendToXeroItemModalData from '../command/SendToXeroItemModalData';

const getModalData = (state: State) => state.backoffice.modals.data as SendToXeroItemModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector(
  [getClientCostArticlesDictOptions, getLoading, getModalData],
  (clientCostArticlesOptions, loading, modalData) => ({
    clientCostArticlesOptions,
    loading,
    clientCostArticleCode: modalData?.invoiceCode,
    documentId: modalData?.documentId,
    walletId: modalData?.walletId,
    requestId: modalData?.requestId,
  })
);
