import State from 'src/app/types/state';
import { createSelector } from 'reselect';
import UseQuoteAsInvoiceModalData from '../command/UseQuoteAsInvoiceModalData';

const modalDataSelector = (state: State) => state.backoffice.modals.data as UseQuoteAsInvoiceModalData;

export default createSelector([modalDataSelector], (data) => ({
  requestId: data?.requestId,
  documentId: data?.documentId,
}));
