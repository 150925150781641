import State from 'src/app/types/state';
import { IQuote, REQUEST_DOCUMENTS_TYPE_ENUM } from 'src/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

const getRequest = (state: State) => state.backoffice.requests.singleRequest.request.value;

const getQuotes = createSelector(
  [getRequest],
  (request) =>
    (request?.documents?.filter((document) => document.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE) ||
      []) as IQuote[]
);

export default createSelector([getQuotes], (quotes) => ({
  quotes,
}));
