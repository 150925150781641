import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  noDataContainer: {
    height: '100%',
    overflowY: 'auto',
  },

  container: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },

  totalRowCell: {
    borderTop: `2px solid ${COLOURS.HUE_COCONUT.HUE_50}`,
  },
});
