import { SxProps, Theme } from '@mui/material/styles';
import { DEFAULT_TABLE_AUTOCOMPLETE_WIDTH } from 'src/pages/backoffice/consts';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    cursor: 'pointer',
    wordBreak: 'break-all',
  },

  autocompleteCell: {
    maxWidth: DEFAULT_TABLE_AUTOCOMPLETE_WIDTH,
  },
});
