import documentInformation, {
  ILedgerDocumentInformationState,
} from 'src/pages/backoffice/Ledger/LedgerDocumentInfo/reducer';
import documentLines, { ILedgerDocumentLinesState } from 'src/pages/backoffice/Ledger/LedgerDocumentLines/reducer';
import document, { ILedgerDocumentState } from 'src/pages/backoffice/Ledger/LedgerDocumentRoot/reducer';
import approveDocumentModal, { IApproveDocumentList } from 'src/pages/backoffice/Modals/ApproveDocumentModal/reducer';
import { combineReducers } from 'redux';
import filters, { ILedgerFiltersState } from './LedgerFilters/reducer';
import list, { ILedgerListState } from './LedgerList/reducer';

export interface ILedgerState {
  filters: ILedgerFiltersState;
  list: ILedgerListState;
  document: ILedgerDocumentState;
  documentInformation: ILedgerDocumentInformationState;
  documentLines: ILedgerDocumentLinesState;
  approveDocumentModal: IApproveDocumentList;
}

export default combineReducers({
  filters,
  list,
  document,
  documentInformation,
  documentLines,
  approveDocumentModal,
});
