import declineDocumentSagas from 'src/pages/backoffice/Modals/DeclineDocumentModal/sagas';
import approveDocumentSagas from 'src/pages/backoffice/Modals/ApproveDocumentModal/sagas';
import createEditContractSagas from './CreateEditContractModal/sagas';
import createEditRequestSagas from './CreateEditRequestModal/sagas';
import createEditWalletSagas from './CreateEditWalletModal/sagas';
import sendForApprovalDocumentSagas from './SendForApprovalModal/sagas';
import sendToErpDocumentSagas from './SendToErpModal/sagas';

export default [
  ...createEditRequestSagas,
  ...createEditWalletSagas,
  ...createEditContractSagas,
  ...sendToErpDocumentSagas,
  ...sendForApprovalDocumentSagas,
  ...declineDocumentSagas,
  ...approveDocumentSagas,
];
