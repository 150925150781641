import State from 'src/app/types/state';
import { DeleteWalletModalData } from 'src/pages/backoffice/Modals/command';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.backoffice.modals.data as DeleteWalletModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector([getModalsData, getLoading], (data, loading) => ({
  id: data?.id,
  redirect: data?.shouldRedirect,
  loading,
}));
