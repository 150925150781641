import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { sendInvoicesBatch } from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/actions';
import { Check } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const SendToErpRequestInvoicesBatchModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const { boatId, requestId, loading } = useAppSelector(selector);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(sendInvoicesBatch({ boatId, requestId }));
  };

  return (
    <Modal open={opened} maxWidth="xs" onClose={onClose} title={<FormattedMessage id="label.send_selected_to_erp" />}>
      <DialogContent>
        <Grid container justifyContent="flex-end" gap={3} component={DialogActions} disableSpacing>
          <Button
            color="blue"
            size="large"
            variant="outlined"
            onClick={onClose}
            label={<FormattedMessage id="label.cancel" />}
          />

          <Button
            color="blue"
            size="large"
            autoFocus
            variant="contained"
            onClick={handleConfirm}
            label={<FormattedMessage id="label.confirm" />}
            disabled={loading}
            loading={loading}
            startIcon={<Check />}
          />
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default SendToErpRequestInvoicesBatchModal;
