import { ScrollMemory, useParams } from '@core-ui/redux-router';
import { TableCell, TableRow, useTableDynamicShadows } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generateEmptyArray } from 'src/app/utils/app';
import NoDataContainer from 'src/components/NoDataContainer';
import TableSkeleton from 'src/components/TableSkeleton';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getLedgerDocumentLines } from 'src/pages/backoffice/Ledger/LedgerDocumentLines/actions';
import LedgerDocumentLinesRow from 'src/pages/backoffice/Ledger/LedgerDocumentLines/LedgerDocumentLinesRow';
import TableHeader from 'src/pages/backoffice/Ledger/LedgerDocumentLines/TableHeader/TableHeader';
import { LEDGER_PATHS } from 'src/pages/backoffice/Ledger/routes';
import { LedgerDocumentPathParams } from 'src/pages/backoffice/Ledger/types';
import selector, { getNoDataContainerData } from './selector';
import useStyles from './styles';

const SCROLLABLE_CONTAINER_ID = 'ledger-document-lines';

const noDataContainerStyle: SxProps<Theme> = {};

const LedgerDocumentLines = () => {
  const { items, total, amountFormatter, getCurrencyCode, clientCostArticlesOptions } = useAppSelector(selector);
  const { documentId } = useParams<LedgerDocumentPathParams>(LEDGER_PATHS);

  const dispatch = useDispatch();
  const styles = useStyles();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const applyRef = useTableDynamicShadows(ref);

  useEffect(() => {
    dispatch(getLedgerDocumentLines(null));
  }, []);

  return (
    <>
      <ScrollMemory elementID={SCROLLABLE_CONTAINER_ID} />

      <NoDataContainer
        selector={getNoDataContainerData}
        loader={<TableSkeleton rows={10} />}
        sx={styles.noDataContainer}
      >
        <Grid item sx={styles.container} ref={applyRef}>
          <Table stickyHeader>
            <TableHeader />

            <TableBody>
              {items?.map((item) => (
                <LedgerDocumentLinesRow
                  key={item.id}
                  item={item}
                  getCurrencyCode={getCurrencyCode}
                  amountFormatter={amountFormatter}
                  documentId={Number(documentId)}
                  clientCostArticlesOptions={clientCostArticlesOptions}
                />
              ))}

              <TableRow>
                <TableCell sx={styles.totalRowCell}>
                  <Typography fontWeight="semiBold">
                    <FormattedMessage id="table.footer.total" />
                  </Typography>
                </TableCell>

                {generateEmptyArray(7).map((_, index) => (
                  <TableCell key={index} sx={styles.totalRowCell} />
                ))}

                <TableCell align="right" sticky right={160} shadow="dynamicLeft" sx={styles.totalRowCell} />

                <TableCell align="right" sx={styles.totalRowCell} sticky right={0}>
                  <Typography fontFamily="mono" fontWeight="semiBold">
                    {amountFormatter(total)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </NoDataContainer>
    </>
  );
};

export default LedgerDocumentLines;
