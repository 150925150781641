import filterSagas from './LedgerFilters/sagas';
import listRowSagas from './LedgerList/LedgerListRow/sagas';
import listSagas from './LedgerList/sagas';
import documentInfoSagas from './LedgerDocumentInfo/sagas';
import documentLinesSagas from './LedgerDocumentLines/sagas';
import documentSagas from './LedgerDocumentRoot/sagas';

export default [
  ...filterSagas,
  ...listRowSagas,
  ...listSagas,
  ...documentSagas,
  ...documentInfoSagas,
  ...documentLinesSagas,
];
