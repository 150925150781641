import { setRequiredFields } from 'src/app/utils/app';
import { WalletInputSchema } from 'src/generated';
import { ValidationErrors } from 'final-form';

export default (values: WalletInputSchema): ValidationErrors => {
  const errors: ValidationErrors = {};

  setRequiredFields(values, errors, ['name', 'wallet_type']);

  return errors;
};
