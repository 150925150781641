import State from 'src/app/types/state';
import { createSelector } from 'reselect';
import DeleteWalletTransactionModalData from '../command/DeleteWalletTransactionModalData';

const getModalData = (state: State) => state.backoffice.modals.data as DeleteWalletTransactionModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector([getModalData, getLoading], (data, loading) => ({
  id: data?.id,
  status: data?.status,
  loading,
}));
