import { getLevelUserApprovalEnabled } from 'src/app/selectors';
import State from 'src/app/types/state';
import { ILedgerFiltersState } from 'src/pages/backoffice/Ledger/LedgerFilters/reducer';
import isNil from 'lodash-es/isNil';
import { createSelector } from 'reselect';

export const keys: Array<keyof ILedgerFiltersState> = [
  'name',
  'dateFrom',
  'dateTo',
  'tags',
  'erpUploadPending',
  'myDuty',
  'documentType',
  'vendor',
  'location',
  'uploadedBy',
  'uploadedFrom',
  'totalFrom',
  'totalTo',
  'costCenters',
  'costArticles',
  'erpCategories',
  'walletIds',
  'contractIds',
  'requestIds',
];

export const getFilters = (state: State) => state.backoffice.ledger.filters;

/*
 * client cost articles (ERP Categories) codes are strings which can contain numbers or letters
 * query parser will convert them to numbers if they are only numbers
 * so we need to convert them to strings to be able to use them in the filter
 * because the filter component only accepts strings
 */
export const getErpCategoriesFilter = createSelector([getFilters], (filters) => {
  if (isNil(filters.erpCategories)) {
    return null;
  }

  if (Array.isArray(filters.erpCategories)) {
    return filters.erpCategories.map((category) => String(category));
  }

  return String(filters.erpCategories);
});

export default createSelector([getFilters, getLevelUserApprovalEnabled], (filters, levelUserApprovalEnabled) => ({
  filters,
  levelUserApprovalEnabled,
}));
