import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import { DocumentSearchResult } from 'src/generated';
import { createAction } from 'redux-actions';

export const reducerName = 'ledger.documents.list';

export const getLedgerList = getList(reducerName);
export const setLedgerList = setList<DocumentSearchResult>(reducerName);

export const getChunkLedgerList = getChunkList(reducerName);
export const setChunkLedgerList = setChunkList<DocumentSearchResult>(reducerName);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
export const setOrder = createAction<SORT_ORDER_ENUM>(`${reducerName}.SET_ORDER`);

export const setLedgerListLoading = setLoading(reducerName);
export const setPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);

export const resetLedgerList = resetState(reducerName);
