import { FileStatusEnum } from 'src/generated';
import { IDeleteWalletTransaction } from 'src/pages/backoffice/Wallets/SingleWalletList/SingleWalletRow/actions';

export default class DeleteWalletTransactionModalData implements IDeleteWalletTransaction {
  id: number;
  status: FileStatusEnum;

  constructor(id: number, status: FileStatusEnum) {
    this.id = id;
    this.status = status;
  }
}
