import Button from '@core-ui/button';
import { COLOURS } from '@core-ui/styles';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { ApproveStatusEnum, DocumentSearchResult } from 'src/generated';
import { openBackofficeModal } from 'src/pages/backoffice/Modals/actions';
import { DeclineDocumentModalData } from 'src/pages/backoffice/Modals/command';
import ApproveDocumentModalData from 'src/pages/backoffice/Modals/command/ApproveDocumentModalData';
import { Check, Prohibit } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

interface IProps {
  item: Nullable<DocumentSearchResult>;
}

const ValidationButtons: FC<IProps> = (props) => {
  const { item } = props;

  const isApproveButtonsDisabled = item?.status !== ApproveStatusEnum.AWAITING;

  const styles = useStyles({ status: item?.status });
  const dispatch = useDispatch();

  const handleOpenApproveModal = () => {
    if (item?.id) {
      dispatch(openBackofficeModal(new ApproveDocumentModalData(item.id, false)));
    }
  };

  const handleOpenDeclineModal = () => {
    if (item?.id) {
      dispatch(openBackofficeModal(new DeclineDocumentModalData(item.id, false)));
    }
  };

  return (
    <Grid item container gap={2} width="auto">
      <Tooltip
        title={
          isApproveButtonsDisabled ? (
            <FormattedMessage
              id="tooltip.document_has_already_been_approved_or_declined"
              values={{ status: item?.status }}
            />
          ) : (
            ''
          )
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleOpenApproveModal}
            sx={styles.approveButton}
            label={<FormattedMessage id="label.approve" />}
            icon={
              <SvgIcon
                htmlColor={
                  item?.status === ApproveStatusEnum.DECLINED
                    ? COLOURS.HUE_COCONUT.HUE_100
                    : COLOURS.HUE_AVOCADO.HUE_100
                }
              >
                <Check />
              </SvgIcon>
            }
          />
        </div>
      </Tooltip>

      <Tooltip
        title={
          isApproveButtonsDisabled ? (
            <FormattedMessage
              id="tooltip.document_has_already_been_approved_or_declined"
              values={{ status: item?.status }}
            />
          ) : (
            ''
          )
        }
        placement="top"
        arrow
      >
        <div>
          <Button
            variant="outlined"
            disabled={isApproveButtonsDisabled}
            onClick={handleOpenDeclineModal}
            sx={styles.declineButton}
            label={<FormattedMessage id="label.decline" />}
            icon={
              <SvgIcon
                htmlColor={
                  item?.status === ApproveStatusEnum.APPROVED
                    ? COLOURS.HUE_COCONUT.HUE_100
                    : COLOURS.HUE_STRAWBERRY.HUE_100
                }
              >
                <Prohibit />
              </SvgIcon>
            }
          />
        </div>
      </Tooltip>
    </Grid>
  );
};

export default ValidationButtons;
