import State from 'src/app/types/state';
import DeleteInboxDocumentData from 'src/pages/backoffice/Modals/command/DeleteInboxDocumentData';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.backoffice.modals.data as DeleteInboxDocumentData;

const getLoading = (state: State) => state.backoffice.modals.loading as boolean;

export default createSelector([getModalsData, getLoading], (data, loading) => ({
  id: data?.id,
  status: data?.status,
  loading,
}));
