import Autocomplete from '@core-ui/autocomplete';
import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { getClientCostArticleDict } from 'src/dictionary/actions';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { sendToERP } from 'src/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import { ArrowRight } from '@phosphor-icons/react';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const SendToErpRequestInvoicesModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const [selectedClientCostArticle, setSelectedClientCostArticle] = useState();
  const { clientCostArticlesOptions, requestId, invoiceDocumentId, loading } = useAppSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientCostArticleDict());
  }, []);

  const submitHandler = () => {
    dispatch(sendToERP({ clientCostArticleCode: Number(selectedClientCostArticle), requestId, invoiceDocumentId }));
  };

  return (
    <Modal
      open={opened}
      maxWidth="md"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.send_invoice_to_erp.title" />}
    >
      <DialogContent>
        <Grid container columnGap={3}>
          <Grid item xs>
            <Autocomplete
              options={clientCostArticlesOptions}
              value={selectedClientCostArticle}
              onChange={setSelectedClientCostArticle}
              label={<FormattedMessage id="label.pick_category" />}
            />
          </Grid>

          <Grid item component={DialogActions}>
            <Button
              variant="contained"
              color="blue"
              size="large"
              loading={loading}
              disabled={!selectedClientCostArticle || loading}
              onClick={submitHandler}
              label={<FormattedMessage id="label.confirm" />}
              endIcon={<ArrowRight />}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Modal>
  );
};

export default SendToErpRequestInvoicesModal;
