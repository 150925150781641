import { vehicleIdSelector } from 'src/app/selectors';
import State from 'src/app/types/state';
import { createSelector } from 'reselect';
import { IRequestForm } from './types';

const getRequestSelector = (state: State) => state.backoffice.requests.singleRequest.request.value;

const createFormInitial = createSelector([getRequestSelector], (request) => {
  if (!request) {
    return null;
  }

  return { ...request, expired_at: request.expired_at! * 1000 } as unknown as IRequestForm;
});

export const getPinMapParams = createSelector([vehicleIdSelector, getRequestSelector], (boatId, request) => ({
  boatId,
  requestId: request?.id,
}));

export default createSelector([createFormInitial], (formInitial) => ({
  formInitial,
}));
