import { HeaderCell, TableRow } from '@core-ui/table';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getLedgerList, setOrder, setOrderBy } from 'src/pages/backoffice/Ledger/LedgerList/actions';
import { getHeaderData } from 'src/pages/backoffice/Ledger/LedgerList/selector';
import React from 'react';
import { batch, useDispatch } from 'react-redux';
import {
  ACTION_BUTTONS_HEADERS,
  DEFAULT_HEADERS,
  VALIDATION_BUTTONS_AND_LEVELS_HEADERS,
  VALIDATION_BUTTONS_HEADERS,
} from './consts';
import useStyles from './styles';

const TableHeader = () => {
  const { orderBy, order, budgetApproveFlowActive, levelUserApprovalEnabled } = useAppSelector(getHeaderData);

  // dynamically adding headers with document validation buttons
  const HEADERS = [
    ...DEFAULT_HEADERS,
    ...(budgetApproveFlowActive
      ? levelUserApprovalEnabled
        ? VALIDATION_BUTTONS_AND_LEVELS_HEADERS
        : VALIDATION_BUTTONS_HEADERS
      : []),
    ...ACTION_BUTTONS_HEADERS(budgetApproveFlowActive),
  ];

  const dispatch = useDispatch();
  const styles = useStyles();

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(setOrder(SORT_ORDER_ENUM.DESC));
      } else {
        dispatch(setOrder(order === SORT_ORDER_ENUM.ASC ? SORT_ORDER_ENUM.DESC : SORT_ORDER_ENUM.ASC));
      }

      dispatch(setOrderBy(value));
      dispatch(getLedgerList(null));
    });
  };

  return (
    <TableHead sx={styles.head}>
      <TableRow>
        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            right={column.right}
            shadow={column.shadow}
            sticky
            isActive={orderBy === column.orderBy}
            onChangeSort={column.orderBy ? handleChangeSort(column.orderBy) : undefined}
            order={order}
            sx={{
              cursor: column.orderBy ? 'pointer' : 'initial',
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
