import State from 'src/app/types/state';
import { DeclineDocumentModalData } from 'src/pages/backoffice/Modals/command';
import { createSelector } from 'reselect';

const getModalsData = (state: State) => state.backoffice.modals.data as DeclineDocumentModalData;

const getLoading = (state: State) => state.backoffice.modals.loading;

export default createSelector([getModalsData, getLoading], (data, loading) => ({
  id: data?.id,
  reloadDocumentList: data?.reloadDocumentList,
  loading,
}));
