import { IXeroModalData } from 'src/pages/backoffice/Modals/types';

export default class SendToErpRequestInvoicesModalData implements IXeroModalData {
  requestId: number;
  invoiceDocumentId: number;

  constructor(requestId: number, invoiceDocumentId: number) {
    this.requestId = requestId;
    this.invoiceDocumentId = invoiceDocumentId;
  }
}
