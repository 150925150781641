import State from 'src/app/types/state';
import DeleteInvoiceModalData from 'src/pages/backoffice/Modals/command/DeleteInvoiceModalData';
import { createSelector } from 'reselect';

const getData = (state: State) => state.backoffice.modals.data as DeleteInvoiceModalData;

const getLoading = (state: State) => state.backoffice.modals.loading as boolean;

export default createSelector([getData, getLoading], (data, loading) => ({
  documentId: data?.documentId,
  isDocumentAlreadyDigitized: data?.isDocumentAlreadyDigitized,
  loading,
}));
