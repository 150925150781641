import { createParseQuery, resetState, setFieldFilter } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'ledger.filters';

export const setTextFilter = setFieldFilter(reducerName);
export const resetLedgerFilters = resetState(reducerName);

export const updateLedgerFiltersQuery = createAction(`${reducerName}.UPDATE_LEDGER_FILTERS_QUERY`);
export const setLedgerFiltersFromQuery = createParseQuery(reducerName);
