import Autocomplete from '@core-ui/autocomplete';
import { TableCell, TableRow } from '@core-ui/table';
import { IOption, Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'src/app/consts/routes';
import { AmountFormatter, CurrencyCodeGetterById } from 'src/app/types/common';
import ApprovalFlowStatus from 'src/components/ApprovalFlowStatus';
import { DocTypePublicNameEnum, DocumentSearchResult } from 'src/generated';
import history from 'src/history';
import { BACKOFFICE_TABS } from 'src/pages/backoffice/consts';
import { LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM, LEDGER_DOCUMENT_TAB_ENUM } from 'src/pages/backoffice/Ledger/consts';
import { updateRequest } from 'src/pages/backoffice/Ledger/LedgerList/LedgerListRow/actions';
import LedgerListRowMoreButton from 'src/pages/backoffice/Ledger/LedgerList/LedgerListRowMoreButton';
import ValidationRowButtons from 'src/pages/backoffice/Ledger/LedgerList/ValidationRowButtons';
import { LedgerDocumentQueryParams } from 'src/pages/backoffice/Ledger/types';
import { Eye, EyeSlash, FileText, Receipt } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import IconButtonWithTooltip from 'src/components/IconButtonWithTooltip';
import { ACTIONS_CELL_WIDTH, VALIDATION_BUTTONS_CELL_WIDTH } from 'src/pages/backoffice/Ledger/LedgerList/consts';
import useStyles from './styles';

interface IProps {
  item: DocumentSearchResult;
  amountFormatter: AmountFormatter;
  getCurrencyCode: CurrencyCodeGetterById;
  budgetApproveFlowActive: boolean;
  levelUserApprovalEnabled: boolean;
  requestsOptions: IOption[];
  boatId: Nullable<number>;
}

const LedgerListRow = (props: IProps) => {
  const {
    item,
    amountFormatter,
    getCurrencyCode,
    budgetApproveFlowActive,
    levelUserApprovalEnabled,
    requestsOptions,
    boatId,
  } = props;

  const styles = useStyles();
  const dispatch = useDispatch();

  const handleOpenItem = () => {
    history.push(
      buildURL<LedgerDocumentQueryParams>(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.LEDGER}/${item.id}`, {
        previous_section: LEDGER_DOCUMENT_PREVIOUS_SECTION_ENUM.LEDGER,
        tab: LEDGER_DOCUMENT_TAB_ENUM.LINES,
      })
    );
  };

  const handleRequestChange = (value: number) => {
    dispatch(
      updateRequest({
        documentId: item.id,
        requestId: value,
      })
    );
  };

  const visibleInAnalyticsIcon = item.is_hide_from_analytics ? <EyeSlash color="red" /> : <Eye />;
  const visibleInAnalyticsTooltip = item.is_hide_from_analytics
    ? 'label.not_visible_in_analytics'
    : 'label.visible_in_analytics';

  const createdAt = item.created_at ? item.created_at.replace(/\./g, '/') : <FormattedMessage id="label.no_info" />;

  const documentTypeIcon = item.document_type === DocTypePublicNameEnum.RECEIPT ? <Receipt /> : <FileText />;
  const documentTypeTooltip =
    item.document_type === DocTypePublicNameEnum.RECEIPT ? 'document_type.receipt' : 'document_type.invoice';

  const uploadedByName = [item.created_by_first_name, item.created_by_last_name].filter(Boolean).join(' ') || (
    <FormattedMessage id="label.no_info" />
  );

  return (
    <TableRow>
      <TableCell sx={styles.nameCell} onClick={handleOpenItem}>
        {item.number}
      </TableCell>

      <TableCell>{item.vendor}</TableCell>

      <TableCell padding="large">
        <Tooltip title={<FormattedMessage id={documentTypeTooltip} />} placement="top" arrow>
          <SvgIcon>{documentTypeIcon}</SvgIcon>
        </Tooltip>
      </TableCell>

      <TableCell>{createdAt}</TableCell>

      <TableCell padding="none" sx={styles.autocompleteCell}>
        <Autocomplete
          isDynamic
          hiddenBorder
          fullWidth
          fullHeight
          size="small"
          value={item.request_id}
          onChange={handleRequestChange}
          options={requestsOptions}
          dataTestId="ledger-table-row-request-autocomplete"
        />
      </TableCell>

      <TableCell>{uploadedByName}</TableCell>

      <TableCell>
        <Typography fontFamily="mono">{getCurrencyCode(item.currency_id)}</Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.total)}
        </Typography>
      </TableCell>

      <TableCell>
        <Tooltip title={<FormattedMessage id={visibleInAnalyticsTooltip} />} placement="top" arrow>
          <SvgIcon>{visibleInAnalyticsIcon}</SvgIcon>
        </Tooltip>
      </TableCell>

      {budgetApproveFlowActive ? (
        levelUserApprovalEnabled ? (
          <>
            <TableCell
              sticky
              right={ACTIONS_CELL_WIDTH + VALIDATION_BUTTONS_CELL_WIDTH}
              shadow="dynamicLeft"
              padding="large"
            >
              <ApprovalFlowStatus item={item} />
            </TableCell>

            <TableCell sticky right={ACTIONS_CELL_WIDTH} padding="medium">
              <ValidationRowButtons item={item} />
            </TableCell>
          </>
        ) : (
          <TableCell sticky right={ACTIONS_CELL_WIDTH} shadow="dynamicLeft" padding="medium">
            <ValidationRowButtons item={item} />
          </TableCell>
        )
      ) : null}

      <TableCell padding="medium" sticky right={0} shadow={budgetApproveFlowActive ? undefined : 'dynamicLeft'}>
        <Grid container gap={2} justifyContent="flex-end" alignItems="center">
          <IconButtonWithTooltip onClick={handleOpenItem} tooltipLabel="label.open_document" />

          <LedgerListRowMoreButton item={item} />
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default LedgerListRow;
