import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import React, { FC } from 'react';

const headerStyle: SxProps<Theme> = {
  backgroundColor: COLOURS.HUE_COCONUT.HUE_153,
  transform: 'none',
};

const lineStyles: SxProps<Theme> = {
  transform: 'none',
};

const ApproveDocumentModalTableSkeleton: FC = () => {
  return (
    <Grid container direction="column" gap={3}>
      <Skeleton sx={headerStyle} height={24} width="100%" />
      <Skeleton sx={lineStyles} height={24} width="100%" />
      <Skeleton sx={lineStyles} height={24} width="100%" />
      <Skeleton sx={lineStyles} height={24} width="100%" />
    </Grid>
  );
};

export default ApproveDocumentModalTableSkeleton;
