import State from 'src/app/types/state';
import { getEmployeeByIdSelector } from 'src/dictionary/selector';
import { ImageCarouselModalData } from 'src/pages/backoffice/Modals/command';
import { createSelector } from 'reselect';

const getCarouselModalData = (state: State) => state.backoffice.modals.data as ImageCarouselModalData;

const getUploadedBy = (state: State) =>
  getEmployeeByIdSelector(state, {
    employeeId: (state.backoffice.modals.data as ImageCarouselModalData)?.uploadedById || null,
  });

export default createSelector([getCarouselModalData, getUploadedBy], (data, uploadedBy) => ({
  pages: data?.files as string[],
  initialPage: data?.initialPage,
  createdAt: data?.createdAt,
  status: data?.status,
  id: data?.id,
  uploadedBy: uploadedBy ? [uploadedBy.first_name, uploadedBy.last_name].filter(Boolean).join(' ') : null,
}));
