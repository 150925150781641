import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import TextField from '@core-ui/text_field';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { declineDocument } from 'src/pages/backoffice/Modals/DeclineDocumentModal/actions';
import { Prohibit } from '@phosphor-icons/react';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const DeclineDocumentModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const { id, reloadDocumentList, loading } = useAppSelector(selector);
  const [declineReason, setDeclineReason] = useState<string>('');

  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(declineDocument({ id, reloadDocumentList, declineReason }));
  };

  return (
    <Modal
      open={opened}
      maxWidth="xs"
      onClose={onClose}
      title={<FormattedMessage id="modal.backoffice.decline_document.title" />}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            multiline
            autoFocus
            minRows={4}
            maxRows={9}
            label={<FormattedMessage id="modal.backoffice.decline_document.decline_reason" />}
            fullWidth
            value={declineReason}
            // @ts-ignore
            onChange={setDeclineReason}
          />

          <DialogActions>
            <Button
              color="blue"
              size="large"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={loading}
              label={<FormattedMessage id="label.decline" />}
              startIcon={<Prohibit />}
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Modal>
  );
};

export default DeclineDocumentModal;
