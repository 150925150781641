import { createSelector } from 'reselect';
import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { getAmountFormatter, getClientCostArticlesDictOptions, getCurrencyCodeById } from 'src/dictionary/selector';

const getLines = (state: State) => state.backoffice.ledger.documentLines;

const getLinesTotal = (state: State) =>
  state.backoffice.ledger.documentLines.value?.reduce<number>((total, currentValue) => {
    if (currentValue.total) {
      return total + currentValue.total;
    }

    return total;
  }, 0);

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getLines], (lines) => ({
  loading: lines.loading,
  loaded: lines.loaded,
  hasData: lines.hasData,
  error: lines.error,
}));

export default createSelector(
  [getLines, getLinesTotal, getAmountFormatter, getCurrencyCodeById, getClientCostArticlesDictOptions],
  (lines, total, amountFormatter, getCurrencyCode, clientCostArticlesOptions) => ({
    items: lines.value,
    total,
    amountFormatter,
    getCurrencyCode,
    clientCostArticlesOptions,
  })
);
