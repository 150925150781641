import State from 'src/app/types/state';
import { NoDataContainerSelector } from 'src/components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

export const getLedgerDocument = (state: State) => state.backoffice.ledger.document;

const getLedgerDocumentDataFiles = (state: State) => state.backoffice.ledger.document.value?.files;

export const getLedgerDocumentImageLinks = createSelector(
  [getLedgerDocumentDataFiles],
  (files) => files?.map((file) => file.link) ?? []
);

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getLedgerDocument], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));
