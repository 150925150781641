import { useParams } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import ImagesVerticalCarousel from 'src/components/ImagesVerticalCarousel';
import { SidePanelLeft } from 'src/components/SidePanel';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  SINGLE_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH,
  SINGLE_DOCUMENT_PREVIEW_PANEL_MAX_WIDTH,
  SINGLE_DOCUMENT_PREVIEW_PANEL_MIN_WIDTH,
} from 'src/pages/backoffice/Ledger/consts';
import LedgerDocumentContent from 'src/pages/backoffice/Ledger/LedgerDocumentContent';
import { getLedgerDocumentImageLinks } from 'src/pages/backoffice/Ledger/LedgerDocumentRoot/selector';
import { LEDGER_PATHS } from 'src/pages/backoffice/Ledger/routes';
import { LedgerDocumentPathParams } from 'src/pages/backoffice/Ledger/types';
import React from 'react';
import useStyles from './styles';

const LedgerDocumentRoot = () => {
  const imageLinks = useAppSelector(getLedgerDocumentImageLinks);
  const { documentId } = useParams<LedgerDocumentPathParams>(LEDGER_PATHS);
  const styles = useStyles();

  return (
    <Grid container overflow="hidden" flex="1" wrap="nowrap" sx={styles.container}>
      <Grid item paddingY={6}>
        <SidePanelLeft
          defaultWidth={SINGLE_DOCUMENT_PREVIEW_PANEL_DEFAULT_WIDTH}
          minWidth={SINGLE_DOCUMENT_PREVIEW_PANEL_MIN_WIDTH}
          maxWidth={SINGLE_DOCUMENT_PREVIEW_PANEL_MAX_WIDTH}
        >
          <ImagesVerticalCarousel documentId={documentId} imageLinks={imageLinks} defaultWidth={700} />
        </SidePanelLeft>
      </Grid>

      <Grid item container direction="column" sx={styles.contentContainer} wrap="nowrap">
        <LedgerDocumentContent />
      </Grid>
    </Grid>
  );
};

export default LedgerDocumentRoot;
