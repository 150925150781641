import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TRANSITION_TIMING_FUNCTION } from 'src/app/consts/app';
import { DRAWER_GRID_ITEM_WIDTH, DRAWER_WIDTH } from 'src/pages/backoffice/Ledger/consts';

export default (): Record<string, SxProps<Theme>> => ({
  drawer: {
    width: DRAWER_WIDTH,
    backgroundColor: `${COLOURS.HUE_COCONUT.HUE_16} !important`,
  },
  button: {
    backgroundColor: `${COLOURS.HUE_COCONUT.HUE_8} !important`,
    borderColor: 'transparent !important',

    '&:hover': {
      borderColor: `${COLOURS.HUE_COCONUT.HUE_100} !important`,
    },
  },
  switchContainer: {
    color: COLOURS.HUE_COCONUT.HUE_153,
    cursor: 'pointer',
    transition: `all 250ms ${TRANSITION_TIMING_FUNCTION}`,

    '&:hover': {
      color: COLOURS.HUE_COCONUT.HUE_195,
    },
  },
  gridItem: {
    maxWidth: `${DRAWER_GRID_ITEM_WIDTH}px !important`,
  },
});
