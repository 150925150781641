import { createAction } from 'redux-actions';

export const reducerName = 'backoffice.modals.decline_document';

export interface IDeclineDocumentModalData {
  id: number;
  reloadDocumentList?: boolean;
}

export interface IDeclineDocument extends IDeclineDocumentModalData {
  declineReason?: string;
}

export const declineDocument = createAction<IDeclineDocument>(`${reducerName}.DECLINE_DOCUMENT`);
