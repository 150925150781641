import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import React, { FC } from 'react';

const cancelButtonStyles: SxProps<Theme> = {
  background: COLOURS.HUE_COCONUT.HUE_12,
};

const approveButtonStyles: SxProps<Theme> = {
  background: COLOURS.HUE_FIG.HUE_100,
};

const ApproveDocumentModalButtonsSkeleton: FC = () => {
  return (
    <Grid container justifyContent="flex-end" gap={3}>
      <Skeleton width={82} height={48} variant="rounded" sx={cancelButtonStyles} />

      <Skeleton width={113} height={48} variant="rounded" sx={approveButtonStyles} />
    </Grid>
  );
};

export default ApproveDocumentModalButtonsSkeleton;
