import State from 'src/app/types/state';
import { createSelector } from 'reselect';

const getQuoteChecked = (state: State, props: { quoteId: number }) =>
  state.backoffice.requests.singleRequest.compareQuote.checkedQuoteIds[props.quoteId].checked;

export default () =>
  createSelector([getQuoteChecked], (checked) => ({
    checked,
  }));
