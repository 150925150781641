import { FileStatusEnum } from 'src/generated';
import { IDeleteInboxDocument } from 'src/pages/backoffice/Inbox/InboxList/InboxRow/actions';

export default class DeleteSingleContractTransactionModalData implements IDeleteInboxDocument {
  id: number;
  status: FileStatusEnum;

  constructor(id: number, status: FileStatusEnum) {
    this.id = id;
    this.status = status;
  }
}
