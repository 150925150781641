import { setRequiredFields } from 'src/app/utils/app';
import { isValidEmail } from 'src/app/utils/validate';
import { IContractFormData } from 'src/pages/backoffice/Modals/CreateEditContractModal/actions';
import {
  FLOAT_NUMBER_REGEX,
  NEGATIVE_FLOAT_NUMBER_REGEX,
} from 'src/pages/backoffice/Modals/CreateEditContractModal/consts';
import { ValidationErrors } from 'final-form';
import set from 'lodash-es/set';
import { FormattedMessage } from 'react-intl';

type fieldKeys = (keyof IContractFormData | 'notification.notify_base_date' | 'notification.notify_every_days')[];

const negativeNumberErrorText = <FormattedMessage id="form.errors.negative_number_forbidden" />;
const moreThanZeroErrorText = <FormattedMessage id="form.errors.number_must_be_more_than_zero" />;
const floatNumberErrorText = <FormattedMessage id="form.errors.float_numbers_forbidden" />;
const tooBigNumberErrorText = <FormattedMessage id="form.errors.value_must_be_lower_than_366" />;
const invalidEmailErrorText = <FormattedMessage id="form.errors.invalid_email" />;

export default (values: IContractFormData): ValidationErrors => {
  const errors: ValidationErrors = {};
  const notificationRequired = [
    'name',
    'currency_id',
    'periodicity',
    'amount',
    'vendor_id',
    'payment_date',
  ] as fieldKeys;

  if (values.notification_active) {
    notificationRequired.push('notification.notify_every_days');
    notificationRequired.push('notification.notify_base_date');
  }

  // setRequiredFields принимает плоский массив имён полей формы, но самой форме требуются вложенные имена полей поэтому приходится кастить
  setRequiredFields(values, errors, notificationRequired as (keyof IContractFormData)[]);

  if (values.manager_info?.email && !isValidEmail(values.manager_info.email)) {
    set(errors, 'manager_info.email', invalidEmailErrorText);
  }

  if (values.amount && String(values.amount).match(NEGATIVE_FLOAT_NUMBER_REGEX)) {
    set(errors, 'amount', negativeNumberErrorText);
  }

  if (values.notification_active) {
    const daysInputString = String(values.notification.notify_every_days);

    if (daysInputString.match(FLOAT_NUMBER_REGEX)) {
      set(errors, 'notification.notify_every_days', floatNumberErrorText);
    }

    if (values.notification.notify_every_days <= 0) {
      set(errors, 'notification.notify_every_days', moreThanZeroErrorText);
    }

    if (values.notification.notify_every_days > 366) {
      set(errors, 'notification.notify_every_days', tooBigNumberErrorText);
    }
  }

  return errors;
};
