import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@core-ui/button';
import { submitForm } from 'src/app/utils/app';
import { useAppSelector } from 'src/hooks/useAppSelector';

import { CREATE_REQUEST_FORM_ID } from '../../consts';
import selector from './selector';

const FormSubmitButton: FC = () => {
  const { loading } = useAppSelector(selector);

  const onSubmit = () => {
    submitForm(CREATE_REQUEST_FORM_ID);
  };

  return (
    <Button
      variant="contained"
      color="blue"
      type="button"
      onClick={onSubmit}
      disabled={loading}
      loading={loading}
      label={<FormattedMessage id="label.save_request" />}
    />
  );
};

export default FormSubmitButton;
