import { COLOURS } from '@core-ui/styles';
import { TableCell, TableRow } from '@core-ui/table';
import Typography from '@mui/material/Typography';
import { getCostArticleMappingEnabled } from 'src/app/selectors';
import { AmountFormatter } from 'src/app/types/common';
import { CARemainingDocLine } from 'src/generated';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React, { FC } from 'react';

interface IProps {
  item: CARemainingDocLine;
  amountFormatter: AmountFormatter;
}

const ApproveDocumentModalListRow: FC<IProps> = (props) => {
  const { item, amountFormatter } = props;

  const costArticleMappingEnabled = useAppSelector(getCostArticleMappingEnabled);

  const isBudgetOverrun = item.doc_ca_price > item.remaining;
  const costArticleName = costArticleMappingEnabled ? item.cost_article_clients_name : item.cost_article_name;

  return (
    <TableRow>
      <TableCell>{costArticleName}</TableCell>

      <TableCell align="right">
        <Typography
          fontFamily="mono"
          fontWeight="semiBold"
          color={isBudgetOverrun ? COLOURS.HUE_STRAWBERRY.HUE_100 : COLOURS.HUE_COCONUT.HUE_195}
        >
          {amountFormatter(item.doc_ca_price)}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(item.remaining)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ApproveDocumentModalListRow;
