import makeStyles from '@mui/styles/makeStyles';
import { COLOURS } from '@core-ui/styles';

export default makeStyles({
  dropzone: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${COLOURS.BORDER.MAIN}`,
    borderRadius: 4,
    cursor: 'pointer',
    minHeight: 170,
  },
  dropzoneArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});
