import { ITableColumn } from 'src/pages/backoffice/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const HEADERS: ITableColumn[] = [
  {
    key: 'cost_article',
    title: <FormattedMessage id="table.header.cost_article" />,
    width: 384,
  },
  {
    key: 'doc_amount',
    title: <FormattedMessage id="table.header.doc_amount" />,
    width: 168,
    align: 'right',
  },
  {
    key: 'remaining_budget',
    title: <FormattedMessage id="table.header.remaining_budget" />,
    width: 168,
    align: 'right',
  },
];
