import { Nullable } from '@core-ui/types';
import { ContractOutputSchema } from 'src/generated';
import { ICreateEditContractModalData } from 'src/pages/backoffice/Modals/CreateEditContractModal/actions';

export default class CreateEditContractModalData implements ICreateEditContractModalData {
  initialContractData: Nullable<ContractOutputSchema>;
  shouldRedirect: boolean;
  refetchSinglePage?: boolean;

  constructor(
    initialContractData: Nullable<ContractOutputSchema>,
    shouldRedirect: boolean,
    refetchSinglePage?: boolean
  ) {
    this.initialContractData = initialContractData;
    this.shouldRedirect = shouldRedirect;
    this.refetchSinglePage = refetchSinglePage;
  }
}
