import { Nullable, SORT_ORDER_ENUM } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { POST } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { vehicleIdSelector } from 'src/app/selectors';
import { toArray } from 'src/app/utils/app';
import { DocSortingColumns, DocTypeLite, DocumentSearchRequest, DocumentSearchResult } from 'src/generated';
import { ILedgerFiltersState } from 'src/pages/backoffice/Ledger/LedgerFilters/reducer';
import { getFilters } from 'src/pages/backoffice/Ledger/LedgerFilters/selector';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { getLedgerSortOrder, getPaginationPage } from './selector';

const LIMIT = 50;

interface SortAndOrderDesc {
  orderBy: Nullable<DocSortingColumns>;
  order: SORT_ORDER_ENUM;
}

interface IFetchDocuments {
  page: number;
}

function* fetchDocuments({ page }: IFetchDocuments) {
  const filters: ILedgerFiltersState = yield select(getFilters);
  const boatId: number = yield select(vehicleIdSelector);
  const { orderBy, order }: SortAndOrderDesc = yield select(getLedgerSortOrder);

  const url = buildURL('/document/get_by_filter', {
    page,
    per_page: LIMIT,
    order_by: orderBy || undefined,
    is_desc: order === SORT_ORDER_ENUM.DESC,
    my_duty: filters.myDuty ?? undefined,

    /*
     * инверсия логики: true -> false, false -> undefined т.к. если erp upload is pending,
     * то документ is not sent to xero. undefined потому что так работает фильтрация на бэке
     */
    is_sent_xero: filters.erpUploadPending ? false : undefined,
  });

  const documents: DocumentSearchResult[] = yield call(POST, url, {
    boat_id: boatId,
    number: filters.name ? filters.name : undefined,
    date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
    date_to: filters.dateTo ? Number(filters.dateTo) : undefined,

    // for filters with multiple options backend expects arrays of values
    doc_types:
      filters.documentType && filters.documentType.length > 0
        ? toArray(filters.documentType)
        : [DocTypeLite.INVOICE, DocTypeLite.RECEIPT],
    vendor_ids: filters.vendor ? (Array.isArray(filters.vendor) ? filters.vendor : [filters.vendor]) : undefined,
    uploaded_by_ids: filters.uploadedBy ? toArray(filters.uploadedBy) : undefined,
    uploaded_from: filters.uploadedFrom ? toArray(filters.uploadedFrom) : undefined,
    total_from: filters.totalFrom ? Number(filters.totalFrom) : undefined,
    total_to: filters.totalTo ? Number(filters.totalTo) : undefined,
    cost_center_ids: filters.costCenters ? toArray(filters.costCenters) : undefined,
    cost_articles: filters.costArticles ? toArray(filters.costArticles) : undefined,
    erp_categories: filters.erpCategories ? toArray(filters.erpCategories) : undefined,
    wallet_ids: filters.walletIds ? toArray(filters.walletIds) : undefined,
    request_ids: filters.requestIds ? toArray(filters.requestIds) : undefined,
  } as DocumentSearchRequest);

  return documents;
}

function* getLedgerDocuments() {
  try {
    const documents: DocumentSearchResult[] = yield fetchDocuments({ page: 0 });

    yield put(actions.setPaginationPage(0));
    yield put(
      actions.setLedgerList({
        value: documents,
        hasData: Boolean(documents.length),
        isAllDataReceived: documents.length < LIMIT,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setLedgerList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

function* getChunkLedgerDocuments() {
  try {
    const page: number = yield select(getPaginationPage);
    const newDocuments: DocumentSearchResult[] = yield fetchDocuments({ page: page + 1 });

    yield all([
      put(actions.setPaginationPage(page + 1)),
      put(
        actions.setChunkLedgerList({
          value: newDocuments,
          isAllDataReceived: newDocuments.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.getLedgerList, getLedgerDocuments),
  takeLatest(actions.getChunkLedgerList, getChunkLedgerDocuments),
];
