import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import TextField from '@core-ui/text_field';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { updateInvoiceStatus } from 'src/pages/backoffice/Requests/SingleRequest/InvoicesList/actions';
import { PAYMENT_REQUEST_APPROVE_STATUS } from 'src/pages/backoffice/Requests/types';
import { X } from '@phosphor-icons/react';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const RejectRequestModal: FC<IProps> = (props) => {
  const { opened, onClose } = props;

  const dispatch = useDispatch();
  const [reason, setReason] = useState('');
  const { loading } = useAppSelector(selector);

  const handleRejectClick = () => {
    dispatch(
      updateInvoiceStatus({
        status: PAYMENT_REQUEST_APPROVE_STATUS.DECLINED,
        reason,
      })
    );
  };

  return (
    <Modal open={opened} maxWidth="xs" onClose={onClose} title={<FormattedMessage id="reject" />}>
      <DialogContent>
        <Grid container>
          <TextField
            multiline
            autoFocus
            minRows={3}
            maxRows={5}
            label={<FormattedMessage id="label.description" />}
            fullWidth
            value={reason}
            // @ts-ignore
            onChange={setReason}
          />
        </Grid>

        <DialogActions>
          <Button
            color="blue"
            variant="contained"
            size="large"
            onClick={handleRejectClick}
            label={<FormattedMessage id="reject" />}
            loading={loading}
            disabled={loading || !reason}
            startIcon={<X />}
          />
        </DialogActions>
      </DialogContent>
    </Modal>
  );
};

export default RejectRequestModal;
