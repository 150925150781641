import State from 'src/app/types/state';
import { convertValueToUTCDate } from 'src/app/utils/dates';
import { getCurrencyDictOptions, getVendorsDictOptions } from 'src/dictionary/selector';
import { CreateEditContractModalData } from 'src/pages/backoffice/Modals/command';
import { createSelector } from 'reselect';

const getLoading = (state: State) => state.backoffice.modals.loading;

const getInitialContractData = (state: State) =>
  (state.backoffice.modals.data as CreateEditContractModalData)?.initialContractData;

const getRedirect = (state: State) => (state.backoffice.modals.data as CreateEditContractModalData)?.shouldRedirect;

const getShouldRefetchSinglePage = (state: State) =>
  (state.backoffice.modals.data as CreateEditContractModalData)?.refetchSinglePage;

export default createSelector(
  [
    getInitialContractData,
    getRedirect,
    getShouldRefetchSinglePage,
    getLoading,
    getCurrencyDictOptions,
    getVendorsDictOptions,
  ],
  (initialValues, redirect, refetchSinglePage, loading, currencyOptions, vendorOptions) => {
    const expires_at = initialValues?.expires_at && convertValueToUTCDate(initialValues.expires_at);
    const payment_date = initialValues?.payment_date && convertValueToUTCDate(initialValues.payment_date);
    const notify_every_days = initialValues?.notification?.notify_every_days;
    const notify_base_date =
      initialValues?.notification?.notify_base_date &&
      convertValueToUTCDate(initialValues.notification.notify_base_date);

    const defaultNotificationActive = Boolean(
      initialValues?.notification?.notify_every_days && initialValues?.notification?.notify_base_date
    );

    return {
      isModeEdit: Boolean(initialValues),
      initialValues: {
        ...initialValues,
        expires_at,
        payment_date,
        notification: {
          notify_every_days,
          notify_base_date,
        },
        notification_active: defaultNotificationActive,
      },
      redirect,
      refetchSinglePage,
      loading,
      currencyOptions,
      vendorOptions,
      defaultNotificationActive,
    };
  }
);
