import { closeDialog, openDialog } from '@core-ui/reducers_handlers';
import { IModalsData } from 'src/pages/backoffice/Modals/reducer';
import { createAction } from 'redux-actions';

export const reducerName = 'backoffice.modals';

export const openBackofficeModal = openDialog<IModalsData>(reducerName);
export const closeBackofficeModal = closeDialog(reducerName);

export const toggleModalLoading = createAction(`${reducerName}.TOGGLE_MODAL_LOADING`);
