import reducer from '@core-ui/reducers_handlers';
import { ISetLoading } from '@core-ui/reducers_handlers/actions';
import { handleActions } from 'redux-actions';
import Immutable from '@core-ui/immutable';
import * as actions from './actions';

export interface ILedgerDocumentInformationState extends ISetLoading {}

const defaultState = Immutable<ILedgerDocumentInformationState>({
  loading: false,
});

export default handleActions<typeof defaultState, any>(
  {},
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
