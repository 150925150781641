import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { ApproveStatusEnum } from 'src/generated';

export default (props: { status: string | undefined }): Record<string, SxProps<Theme>> => ({
  approveButton: {
    minWidth: 'auto',
    borderRadius: '16px !important',

    background: `${
      props.status === ApproveStatusEnum.DECLINED
        ? COLOURS.HUE_COCONUT.HUE_32
        : props.status === ApproveStatusEnum.APPROVED
        ? COLOURS.HUE_AVOCADO.HUE_15
        : COLOURS.WHITE
    } !important`,

    borderColor: `${
      props.status === ApproveStatusEnum.DECLINED
        ? COLOURS.HUE_COCONUT.HUE_32
        : props.status === ApproveStatusEnum.APPROVED
        ? COLOURS.HUE_AVOCADO.HUE_120
        : COLOURS.HUE_COCONUT.HUE_32
    } !important`,

    '&:hover': {
      borderColor: `${
        props.status === ApproveStatusEnum.DECLINED ? COLOURS.HUE_COCONUT.HUE_32 : COLOURS.HUE_AVOCADO.HUE_120
      } !important`,
    },
  },
  declineButton: {
    minWidth: 'auto',
    borderRadius: '16px !important',

    background: `${
      props.status === ApproveStatusEnum.APPROVED
        ? COLOURS.HUE_COCONUT.HUE_32
        : props.status === ApproveStatusEnum.DECLINED
        ? COLOURS.HUE_STRAWBERRY.HUE_15
        : COLOURS.WHITE
    } !important`,

    borderColor: `${
      props.status === ApproveStatusEnum.APPROVED
        ? COLOURS.HUE_COCONUT.HUE_32
        : props.status === ApproveStatusEnum.DECLINED
        ? COLOURS.HUE_STRAWBERRY.HUE_120
        : COLOURS.HUE_COCONUT.HUE_32
    } !important`,

    '&:hover': {
      borderColor: `${
        props.status === ApproveStatusEnum.DECLINED ? COLOURS.HUE_COCONUT.HUE_32 : COLOURS.HUE_STRAWBERRY.HUE_120
      } !important`,
    },
  },
});
