import { FileStatusEnum } from 'src/generated';
import { IImageCarouselModalData } from 'src/pages/backoffice/Modals/types';

export default class ImageCarouselModalData implements IImageCarouselModalData {
  files: (string | undefined)[];
  id?: number;
  status?: string | FileStatusEnum;
  uploadedById?: number;
  createdAt?: string | number;
  initialPage?: number;

  constructor(
    files: (string | undefined)[],
    id?: number,
    status?: string | FileStatusEnum,
    uploadedById?: number,
    createdAt?: string | number,
    initialPage?: number
  ) {
    this.files = files;
    this.id = id;
    this.status = status;
    this.uploadedById = uploadedById;
    this.createdAt = createdAt;
    this.initialPage = initialPage;
  }
}
